<script lang="ts" setup>
import { useActiveElement, useColorMode, useCycleList, useMagicKeys, whenever } from '@vueuse/core'
import { logicAnd } from '@vueuse/math'
import { event } from 'vue-gtag'
import { usePreferences } from '~~/store/preferences'

const mode = useColorMode({ initialValue: 'dark' })
const modes = ['dark', 'light'] // 'cafe', 'contrast'
const { next } = useCycleList(modes, { initialValue: mode })

watch(mode, () => {
  const preferences = usePreferences()
  preferences.colorMood = mode.value as 'dark' | 'light'
  event(`setting_mood_${preferences.colorMood}`)
})

const activeElement = useActiveElement()
const notUsingInput = computed(
  () => activeElement.value?.tagName !== 'INPUT' && activeElement.value?.tagName !== 'TEXTAREA'
) // to prevent changing mood when typing in Input or Textarea

const keys = useMagicKeys()
const ml = keys['m']
whenever(logicAnd(ml, notUsingInput), () => ml.value && next())
</script>

<template>
  <Toggle :id="'mode'" :value="mode === 'dark'" @click="next()" />
</template>
